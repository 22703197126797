import { defineStore } from 'pinia';
import { auth } from '../firebase/index.js';
import { signOut, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { fetchUserProfile, createNewUserProfile } from '../firebase/userDb';
import router from '../routes'

//placeholder variables for the intialPromise
let resolve;
let reject;

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        fetchState: 'standby',
        data: null,
        response: null,
        profile: null,
        // this is a promise that will be resolved when both fetchUser has fetched the user profile from the database AND
        // setUser has been called to set the user in the store
        // needed so that the router guard can wait for the user to be fetched before allowing or blocking access to certain routes
        initialPromise: new Promise((res, rej) => {
            resolve = res;
            reject = rej;
        }),
    }),
    actions: {
        //returns the current user's display name
        getName(){
            console.log('getName called in store');
            console.log(this.profile.name);
        },

        setUser(user, profile) {
            this.data = user;
            this.profile = profile;
            this.fetchState = 'resolved';
        },

        clearUser() {
            this.data = null;
            this.profile = null;
            this.fetchState = 'standby';
        },

        async googleLogin() {
            console.log('userStore googleLogin() function called');
            
            //create Auth provider
            const provider = new GoogleAuthProvider();

            try {

                const result = await signInWithPopup(auth, provider);
        
            } catch (err) {
                console.log(err);
                alert(err.message);
            }

            //gets user's unique id
            console.log(auth.currentUser)
            const userId = auth.currentUser.uid;
            console.log(`User ${userId} successfully logged in`);

            //attempts to fetch the user's profile from the users collection in db
            const profile = await fetchUserProfile(userId);

            if (!profile) {
                console.log('Need to create new record for user in db');

                //creates new user profile in db
                await createNewUserProfile(userId, {
                    admin: false,
                    manager: false,
                    salesRep: false,
                    email: auth.currentUser.email,
                    name: auth.currentUser.displayName,
                });

                //sets the current permissions to false
                this.setUser(auth.currentUser, {
                    admin: false,
                    manager: false,
                    salesRep: false,
                    email: auth.currentUser.email,
                    name: auth.currentUser.displayName,
                });

            } else {
                //otherwise set the user's profile to the profile fetched from the db
                this.setUser(auth.currentUser, profile);
            }

            const route = router.currentRoute._value.query.redirect;

            //moves user to whatever page they were trying to access before they logged in
            router.push(route || '/')

        },

        // async login(details) {
        //     const { email, password } = details;

        //     console.log('userStore login() function called')

        //     try {
        //         //sign in function from firebase, requires auth object to know which firebase to connect to
        //         await signInWithEmailAndPassword(auth, email, password);

        //     } catch (err) {
        //         //switch to throw different error messages based on error code
        //         switch (err.code) {
        //             case 'auth/user-not-found':
        //                 alert("User Not Found");
        //                 break;
        //             case 'auth/wrong-password':
        //                 alert("Wrong Password");
        //                 break;
        //             default:
        //                 alert(err.message);
        //         }
        //         return;
        //     }

        //     //gets user's unique id
        //     const userId = auth.currentUser.uid;

        //     //fetches user's profile from the users collection in db
        //     const profile = await fetchUserProfile(userId);

        //     //calls the setUser method on the store to set the current user's data
        //     this.setUser(auth.currentUser, profile);

        //     const route = router.currentRoute._value.query.redirect;

        //     //moves user to whatever page they were trying to access before they logged in
        //     router.push(route || '/')

        // },

        async logout() {
            console.log('userStore logout() function ran')
            this.fetchState = 'pending';

            //signs out user from firebase
            await signOut(auth);

            //clears user from user store
            this.clearUser();

            //refreshes page
            router.push('/login');
        },

        //refreshes user info whenever Auth State changes
        async fetchUser(user) {
            // console.log('userStore fetchUser() function called');
            this.fetchState = 'pending';
            if (user) {
                //gets user's unique id
                const userId = auth.currentUser.uid;

                //fetches user's profile from the users collection in db
                const profile = await fetchUserProfile(userId);

                //calls the setUser method on the store to set the current user's data
                this.setUser(auth.currentUser, profile);

                this.fetchState = 'resolved';
            } else {
                this.data = null;
                this.profile = null;
                this.fetchState = 'rejected';
            }
            resolve();
        },


    }
})